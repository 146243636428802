<template>
  <div class="card" v-if="!!vesselResponse">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-4">
          <h5 class="text-danger p-3">COMPANY INSTRUCTION</h5>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">REPORT NUMBER : </h6>
        </div>
        <div class="col-lg-9">
          <template v-if="!!vesselResponse.report">
            <input type="text" class="form-control form-control-sm"
                   disabled
                   :value="vesselResponse.report.code | toUpper"
                   name="report-number"
                   id="report-number">
          </template>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">INSTRUCTION : </h6>
        </div>
        <div class="col-lg-9">
          <template v-if="!!vesselResponse.instruction">
            <input type="text" class="form-control form-control-sm"
                   disabled
                   :value="vesselResponse.instruction.title | toUpper"
                   name="instruction-title"
                   id="instruction-title">
          </template>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">TYPE OF INSTRUCTION : </h6>
        </div>
        <div class="col-lg-9">
          <template v-if="!!vesselResponse.instruction">
            <input type="text" class="form-control form-control-sm"
                   disabled
                   :value="vesselResponse.instruction.instruction_type | toUpper"
                   name="instruction_title"
                   id="instruction_title">
          </template>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">RELATED PARTIES : </h6>
        </div>
        <div class="col-lg-9">
          <template v-if="!!vesselResponse.instruction">
            <input type="text" class="form-control form-control-sm"
                   disabled
                   :value="vesselResponse.instruction.related_party | toUpper"
                   name="instruction_title"
                   id="instruction_title">
          </template>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">INSTRUCTION DETAIL : </h6>
        </div>
        <div class="col-lg-9">
          <template v-if="!!vesselResponse.instruction">
            <div id="textArea" class="pre-wrap-txt textarea-display-card" v-html="vesselResponse.instruction.detail"/>
          </template>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">INSTRUCTION ATTACHMENTS : </h6>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-2"  v-for="attachment in instructionFiles">
              <other-comp :no-delete="true"
                          @viewOther="viewInstructionAttachment(attachment,false)"
                          :attachment="attachment" attachment-name="name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">JOB DONE : </h6>
        </div>
        <div class="col-lg-9">
          <vue-editor   :editor-toolbar="toolbar" v-model="vesselResponse.response"></vue-editor>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">ATTACHMENTS : </h6>
        </div>
        <div class="col-lg-9">
          <div class="row justify-content-start mr-1 mb-2 mt-1" >
            <template v-if="!addAttachment">
              <button  class="e-btn e-btn-green ml-3" id="addBtn" @click="addAttachment = !addAttachment" type="button">Add Attachment</button>
            </template>
            <template v-else>
              <button href="#" class="e-btn e-btn-blue ml-3" id="addBtn" type="button" @click="cancelAddAttachment">Cancel</button>
            </template>
          </div>
          <input type="file" v-if="addAttachment"
                 ref="ci_files"
                 class="form-control-file mb-2" id="docLists"
                 @change.prevent="saveAttachments"
                 multiple>
          <div class="row" v-else>
            <div class="col-2" v-for="attachment in instructionAttachments">
              <other-comp
                @viewOther="viewInstructionAttachment(attachment,true)"
                @deleteOther="deleteInstructionAttachment"
                :attachment="attachment" attachment-name="name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">JOB STATUS : </h6>
        </div>
        <div class="col-lg-9">
          <select class="form-control form-control-sm" v-model="vesselResponse.job_status_id">
            <option :value="status.id" v-for="status in jobStatuses" >
              {{status.name | toUpper}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold mb-0">ACKNOWLEDGEMENT STATUS : </h6>
        </div>
        <div class="col-lg-9">
          <select class="form-control form-control-sm" disabled v-model="vesselResponse.acknowledgement_status_id">
            <option :value="status.id" v-for="status in acknowledgementStatuses" v-model="vesselResponse.acknowledgement_status_id">
              {{status.name | toUpper}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-3">
          <h6 class="font-weight-bold">Comments : </h6>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-12">
              <template v-if="!!vesselResponse">
                <CIComment :key="vesselResponse.id" :vessel-response="vesselResponse" v-if="!!vesselResponse.id" :ciVesselId="vesselResponse.id"></CIComment>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-2 align-items-center mb-2" v-show="isVesselResponseModified">
        <div class="col-lg-12 text-right">
          <button type="button" class="e-btn e-btn-green e-btn-lg" @click="submitResponse">Submit</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OtherComp from "@/components/elements/OtherComp.vue";
import {CI_INDEX, CI_VESSEL_INDEX} from "@/services/ConstantService";
import axios from "axios";
import {FileService} from "@/services/FileService";
import {AlertService} from "@/services/AlertService";
import CIComment from "@/components/modules/ci/CIComment.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import ObjectMixin from "@/mixins/ObjectMixin";

export default {
  name: "VesselCompanyInstructionResponse",
  components: {CIComment, OtherComp},
  mixins:[permissionMixin,ObjectMixin],

  data(){
    return{
      vesselResponse : {},
      addAttachment:false,
      tempResponse:{},
      isVesselResponseModified:false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]
    }
  },

  computed:{
    ...mapGetters(['instructionFiles','instructionAttachments','jobStatuses','acknowledgementStatuses'])
  },

  methods: {
    ...mapActions(['getVesselResponse', 'getVesselAttachments', 'getAcknowledgementStatuses', 'getJobStatuses','addVesselAttachments','deleteVesselAttachment','sendVesselResponse', 'getCompanyInstructionAttachments']),
    async viewInstructionAttachment(attachment, fromVessel = true) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();

      const mainRoute = fromVessel ? CI_VESSEL_INDEX : CI_INDEX

      const url = [mainRoute, attachment.referencable_id, 'attachment', attachment.id].join('/')

      const response = await axios.get(url, {responseType: 'blob'})

      if (response && response.status == 200) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response.data, attachment)
        } else {
          await FileService.downloadDocument(response.data, attachment, 'name');
        }
        swal.close();
      } else {
        AlertService.errorAlert('File not exists', "View Attachment")
      }
    },
    async deleteInstructionAttachment(attachment) {
      if (await AlertService.confirmDeleteAlert('DELETION OF COMPANY INSTRUCTION ATTACHMENT', 'Do you want to Delete this Attachment?', 'Yes, Proceed!')) {
        const response = await this.deleteVesselAttachment({id:this.vesselResponse.id,attachment_id:attachment.id});
        if (response) {
          AlertService.successAlert('Company Instruction Attachment has been Deleted Successfully!', 'Delete Company Instruction Attachment');
        }
      }
    },
    async cancelAddAttachment() {
      if (await AlertService.cancelAlert()) {
        this.addAttachment = false;
      }
    },
    async saveAttachments() {
      const formData = new FormData();
      if (this.$refs.ci_files.files) {
        Array.prototype.forEach.call(this.$refs.ci_files.files, file => {
          formData.append("files[]", file)
        });
      }
      const params = {id: this.vesselResponse.id, form: formData}
      const response = await this.addVesselAttachments(params);
      if (response) {
        AlertService.successAlert('Attachment Added Successfully', 'Add Attachments')
        this.addAttachment = false;
      }
    },
    async submitResponse() {
      if(await AlertService.confirmUpdateAlert('Do you want to Update your Response?')){
        const params={
          id:this.vesselResponse.id,
          job_status_id:this.vesselResponse.job_status_id,
          response:this.vesselResponse.response
        }
        const response = await this.sendVesselResponse(params)
        if(response){
          AlertService.successAlert('Response has been Updated Successfully!','Submit Response');
          this.isVesselResponseModified=false;
          this.vesselResponse=await this.getVesselResponse({id: this.$route.query.id});
          this.tempResponse=Object.assign({},this.vesselResponse)
        }
      }
    }
  },

  async created() {
    const response = await this.getVesselResponse({id: this.$route.query.id})

    if(response){
      // if(this.isCiOwnInstruction(response)){
        await this.getVesselAttachments({id: this.$route.query.id})
        this.vesselResponse=Object.assign({},response)
        this.tempResponse=Object.assign({},response)
        await this.getCompanyInstructionAttachments({id: this.vesselResponse.instruction.id})
        await this.getJobStatuses();
        await this.getAcknowledgementStatuses();
      // }
    //   else {
    //     AlertService.errorAlert('You do not have access to view company instruction','View Vessel Company Instruction')
    //   }
    }
  },
  watch:{
    'vesselResponse.job_status_id'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempResponse ?? null,this.vesselResponse ?? null)
    },
    'vesselResponse.response'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempResponse ?? null,this.vesselResponse ?? null)
    }
  }
}
</script>

<style scoped>

</style>
